var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"model-progress"},[_c('div',{staticClass:"model-progress-data success",staticStyle:{"background":"linear-gradient(135deg, #c7ffff, #14e5fc)","min-width":"8%"},style:({
      width: _vm.calcWidth(_vm.manual) + '%',
    })},[_c('v-tooltip',{staticStyle:{"min-width":"0px","width":"0px","max-width":"0px"},attrs:{"light":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticStyle:{"text-align":"center"}},on),[_vm._v(_vm._s(_vm.manual)+"("+_vm._s(_vm.calcWidth(_vm.manual))+")% Manual")])]}}])},[_c('span',[_vm._v("Total amount of data that is manually labeled. "+_vm._s(_vm.manual)+" out of "+_vm._s(_vm.total))])])],1),_c('div',{staticClass:"model-progress-data info",staticStyle:{"background":"linear-gradient(135deg, #14fc8c, #14e5fc)"},style:({
      width: _vm.calcWidth(_vm.automatic) + '%',
    })},[_c('v-tooltip',{staticStyle:{"min-width":"0px","width":"0px","max-width":"0px"},attrs:{"light":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticStyle:{"text-align":"center"}},on),[_vm._v(_vm._s(_vm.automatic)+"("+_vm._s(_vm.calcWidth(_vm.automatic))+")% Automatic")])]}}])},[_c('span',[_vm._v("Total amount of data that can be automatically labeled. "+_vm._s(_vm.automatic)+" out of "+_vm._s(_vm.total))])])],1),_c('div',{staticClass:"model-progress-data white",style:({
      width: _vm.calcWidth(_vm.total - (_vm.automatic + _vm.manual)) + '%',
    })},[_c('v-tooltip',{staticStyle:{"min-width":"0px","width":"0px","max-width":"0px"},attrs:{"light":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticStyle:{"text-align":"center"}},on),[_vm._v(_vm._s(_vm.total - (_vm.automatic + _vm.manual))+"("+_vm._s(_vm.calcWidth(_vm.total - (_vm.automatic + _vm.manual)))+")% Ignored")])]}}])},[_c('span',[_vm._v("Total amount of data that is not manually or automatically labeled. "+_vm._s(_vm.total - (_vm.automatic + _vm.manual))+" out of "+_vm._s(_vm.total))])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }