
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";
import { api } from "@/api";
import { readToken } from "@/store/main/getters";

@Component
export default class TextEditor extends Vue {
  @Prop({ default: null })
  public itemId!: any;
  @Prop({ default: null })
  public storeId!: any;
  @Prop({ default: null })
  public modelId!: any;
  @Prop({ default: null })
  public datasetId!: any;
  @Prop({ default: null })
  public workspaceId!: any;
  @Prop({ default: "white" })
  public backgroundColor!: any;
  @Prop({ default: "gray" })
  public dotColor!: any;

  public getError: any = null;
  public setError: any = null;
  public show: boolean = false;
  public editText: string = "";
  public loading: boolean = false;

  public item: any = {};

  public async mounted() {}

  get token() {
    return readToken(this.$store);
  }

  public async trigger() {
    this.show = true;
    this.getItemById();
  }

  public async getItemById() {
    if (this.modelId !== null) {
      this.loading = true;
      this.getError = null;
      this.setError = null;
      api
        .getItemFromModelById(this.token, this.modelId, this.storeId, this.itemId)
        .then((r) => {
          this.item = r.data;
          this.editText = this.item.plain_text;
          this.loading = false;
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
          this.getError = error.response;
        });
    } else {
      this.loading = true;
      this.getError = null;
      this.setError = null;
      api
        .getItemFromDatasetById(
          this.token,
          parseInt(this.$router.currentRoute.params.workspaceid, 10),
          this.datasetId,
          this.itemId,
        )
        .then((r) => {
          this.item = r.data;
          this.editText = this.item.plain_text;
          this.loading = false;
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
          this.getError = error.response;
        });
    }
  }

  public async saveAndExit() {
    this.loading = true;
    this.setError = null;
    if (this.modelId !== null) {
      api
        .updateItemFromModelById(this.token, this.modelId, this.storeId, this.itemId, this.editText)
        .then((r) => {
          this.loading = false;
          if (r.data.succeded === false) {
            console.log("error", r.data);
            this.setError = "Could not save changes. Please try again.";
          } else {
            this.exitDialog();
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
          this.setError = "Could not save changes. Please try again.";
        });
      this.exit(this.editText, this.itemId);
    } else {
      api
        .updateItemFromDatasetById(
          this.token,
          parseInt(this.$router.currentRoute.params.workspaceid, 10),
          this.datasetId,
          this.itemId,
          this.editText,
        )
        .then((r) => {
          this.loading = false;
          if (r.data.succeded === false) {
            console.log("error", r.data);
            this.setError = "Could not save changes. Please try again.";
          } else {
            this.exitDialog();
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
          this.setError = "Could not save changes. Please try again.";
        });
      this.exit(this.editText, this.itemId);
    }
  }

  public exitDialog() {
    this.item = {};
    this.editText = "";
    this.show = false;
  }

  @Emit()
  public exit(newText: string, itemId: string) {}
}
