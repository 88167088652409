
import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class LabelBar extends Vue {
  @Prop(String) public name: string | "";
  @Prop(String) public color: string | "red";
  @Prop(Number) public manual: number | 0;
  @Prop(Number) public automatic: number | 0;

  get total() {
    return this.manual + this.automatic;
  }

  public calcHeight(width): string {
    return Math.round((width / this.total) * 100).toString();
  }

  @Emit()
  public files(e): FileList {
    return e.target.files;
  }

  public trigger() {
    (this.$refs.fileInput as HTMLElement).click();
  }
}
