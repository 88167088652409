
import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class ModelProgress extends Vue {
  @Prop(Number) public total: number | 0;
  @Prop(Number) public manual: number | 0;
  @Prop(Number) public automatic: number | 0;

  public calcWidth(width): string {
    return Math.round((width / this.total) * 100).toString();
  }

  @Emit()
  public files(e): FileList {
    return e.target.files;
  }

  public trigger() {
    (this.$refs.fileInput as HTMLElement).click();
  }
}
